div.md {
    width: 70%;
    height: 70%;
    /* padding */
    padding: 1em;
    /* Center it in the div */
    /* 0 for top and bottom auto for left and right */
    margin: 0 auto;
}

div.math {
    overflow-x: auto
}