/* use the id bookshelf to select the div */
/* height fill whatever space is available in the screen */
/* width fill whatever space is available in the screen */
/* margin: 0 auto; centers the div */
/* text-align: center; centers the text */
/* padding: 1em; adds padding to the text */

#bookshelf {
    width: 80%;
    height: 70%;
    /* Center it in the div */
    margin: 0 auto;
    /* borders */
    border: 1px solid black;
}

/* Center and pad header elements */
h1,
h2,
h3,
h4,
h5,
h6 {
    text-align: center;
}